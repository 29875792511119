import { withServerSideProxy } from '@marketplace-web/shared/ssr'

export const { toggleOneTrustInfoDisplay } = withServerSideProxy(
  {
    toggleOneTrustInfoDisplay: () => {
      window.OneTrust?.ToggleInfoDisplay()
    },
  },
  {},
)
