import { uniq } from 'lodash'
import twitterText from 'twitter-text'

import { toUrlQuery } from '_libs/utils/url'
import { CATALOG_URL } from 'constants/routes'

import { LinkifiedText, LinkReplacement } from './types'

export const convertUrls = (
  text: string,
  replacement: LinkReplacement,
  urlRegex?: RegExp,
): LinkifiedText => {
  // @ts-ignore find a proper way to augment twitter-text module declaration
  const { extractUrl, urlHasProtocol } = twitterText.regexen
  const regex = urlRegex || extractUrl
  const doesBeginWithSpace = /^\s/
  const doesBeginWithNewline = /(\r\n|\r|\n)/
  let index = 0

  const values = {}

  const formattedText = text.replace(regex, url => {
    index += 1
    const rawUrl = url.replace(/\s+/g, '')
    const urlWithProtocol = urlHasProtocol.test(rawUrl) ? rawUrl : `//${rawUrl}`
    const paramName = `link${index}`
    let prefix = ''

    if (doesBeginWithNewline.test(url)) {
      prefix = '\n'
    } else if (doesBeginWithSpace.test(url)) {
      prefix = ' '
    }

    values[paramName] = urlRegex ? replacement(url, url) : replacement(urlWithProtocol, rawUrl)

    return `${prefix}{${paramName}}`
  })

  return {
    text: formattedText,
    values,
  }
}

type Replacement = {
  url: LinkReplacement
  hashtag: LinkReplacement
}

export const hashtagsToCatalogLinks = (
  text: string,
  replacement: LinkReplacement,
): LinkifiedText => {
  try {
    // Gets unique hashtags without hashtag symbol
    const tags = uniq(twitterText.extractHashtags(text)) || []
    const values = {}

    let transformedText = text

    tags.forEach((tag, index) => {
      const paramName = `hashtag${index + 1}`
      const hashtag = `#${tag}`
      const reg = new RegExp(hashtag, 'g')
      const url = `${CATALOG_URL}?${toUrlQuery({ search_text: hashtag })}`
      values[paramName] = replacement(url, hashtag)
      transformedText = transformedText.replace(reg, `{${paramName}}`)
    })

    return {
      text: transformedText,
      values,
    }
  } catch (ex) {
    return { text }
  }
}

export const linkifyContent = (
  text: string,
  replacement: Replacement,
  urlRegex?: RegExp,
): LinkifiedText => {
  try {
    const formattedHashTags = hashtagsToCatalogLinks(text, replacement.hashtag)
    const formattedUrls = convertUrls(formattedHashTags.text, replacement.url, urlRegex)

    const values = {
      ...formattedHashTags.values,
      ...formattedUrls.values,
    }

    return {
      text: formattedUrls.text,
      values: Object.keys(values).length ? values : undefined,
    }
  } catch (exception) {
    return { text }
  }
}
