'use client'

import { unescape } from 'lodash'
import { MouseEvent, useState } from 'react'
import { useIntl } from 'react-intl'

import { useTracking } from '@marketplace-web/shared/event-tracker'

import { clickEvent } from '_libs/common/event-tracker/events'
import { isInternalUrl } from '_libs/utils/url'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import useSystemConfiguration from 'hooks/useSystemConfiguration'

import LeavingVintedModalExperiment from './LeavingVintedModalExperiment'
import { linkifyContent } from './utils'

const TRANSLATION_KEY = 'linkified-message'

const renderHashtag = (href: string, text: string) => <a href={href}>{text}</a>

type Props = {
  text: string
}

const LinkifiedMessage = ({ text }: Props) => {
  const [url, setUrl] = useState<string>()
  const { messages, formatMessage } = useIntl()
  const { track } = useTracking()

  const urlRegexConfig = useSystemConfiguration()?.urlRegex

  const urlRegex = urlRegexConfig ? new RegExp(urlRegexConfig, 'g') : undefined

  // TODO: remove this check or make TS typings more accurate
  if (!text) return null

  // Workaround for race condition when event haven't finished and react clears url which leads to navigation to undefined link
  const handleClose = () => setTimeout(() => setUrl(undefined), 0)

  const handleLinkClick = (event: MouseEvent, link: string) => {
    if (isInternalUrl(link)) return

    track(
      clickEvent({
        screen: Screen.MessageReply,
        target: ClickableElement.ShowLeavingModal,
        targetDetails: link,
      }),
    )

    event.preventDefault()
    setUrl(link)
  }

  const renderUrl = (href: string, formattedText: string) => (
    <a href={href} target="_blank" rel="noreferrer" onClick={event => handleLinkClick(event, href)}>
      {formattedText}
    </a>
  )

  const linkifiedContent = linkifyContent(
    unescape(text),
    {
      url: renderUrl,
      hashtag: renderHashtag,
    },
    urlRegex,
  )

  messages[TRANSLATION_KEY] = linkifiedContent.text

  return (
    <>
      <span>{formatMessage({ id: TRANSLATION_KEY }, linkifiedContent.values)}</span>
      <LeavingVintedModalExperiment url={url} onClose={handleClose} />
    </>
  )
}

export default LinkifiedMessage
