'use client'

import { InputSelect } from '@vinted/web-ui'
import classNames from 'classnames'
import { noop } from 'lodash'
import { ChangeEvent, FocusEvent, ReactNode, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'

import { useTranslate } from '@marketplace-web/shared/i18n'
import { isAndroid, isIOS } from '@marketplace-web/shared/utils'
import useUserAgent from 'hooks/useUserAgent'

import DeprecatedInputText from '../DeprecatedInputText'
import { isNativeDatePickerSupported } from './utils'

type Props = {
  name: string
  validation?: ReactNode
  title?: string | JSX.Element
  value?: string | null
  disabled?: boolean
  styling?: 'narrow' | 'wide' | 'tight'
  theme?: ComponentProps<typeof InputSelect>['theme']
  disableHtmlValidations?: boolean
  autoComplete?: string
  onChange?: (date: string | null) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void
}

type DateSelection = {
  year?: string
  month?: string
  day?: string
}

const toDate = (dateSelection: DateSelection) => {
  const year = Number(dateSelection.year)
  const month = Number(dateSelection.month)
  const day = Number(dateSelection.day)
  const date = new Date(year, month, day)

  if (Number.isNaN(date.getTime())) return undefined

  const shifted = date.getFullYear() !== year || date.getMonth() !== month || date.getDate() !== day

  if (shifted) return undefined

  return date
}

const isDateValid = (date: Date) => !Number.isNaN(date.getTime())

const formatDateForBackend = (date: Date) => {
  return [
    date.getFullYear(),
    `0${date.getMonth() + 1}`.slice(-2),
    `0${date.getDate()}`.slice(-2),
  ].join('-')
}

const InputBirthdate = ({
  name,
  validation,
  title,
  disabled,
  styling,
  theme,
  value,
  disableHtmlValidations,
  autoComplete,
  onChange = noop,
  onBlur = noop,
  onFocus = noop,
}: Props) => {
  const [dateSelection, setDateSelection] = useState<DateSelection>({})

  const intl = useIntl()
  const translate = useTranslate('birthdate')
  const userAgent = useUserAgent()

  useEffect(() => {
    if (!value) return

    const date = new Date(value)

    if (!isDateValid(date)) return

    setDateSelection({
      year: date.getFullYear().toString(),
      month: date.getMonth().toString(),
      day: date.getDate().toString(),
    })
  }, [value])

  function handleDateFieldChange(field: string) {
    return function handleDateChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
      const newDateSelection = {
        ...dateSelection,
        [field]: event.target.value,
      }

      const newDate = toDate(newDateSelection)

      if (newDate) {
        onChange(formatDateForBackend(newDate))
      } else {
        onChange(null)
      }

      setDateSelection(newDateSelection)
    }
  }

  function handleNativeDateChange(event: ChangeEvent<HTMLInputElement>) {
    const { value: inputValue } = event.target

    onChange(inputValue)

    if (inputValue) {
      const date = new Date(inputValue)
      setDateSelection({
        year: date.getFullYear().toString(),
        month: date.getMonth().toString(),
        day: date.getDay.toString(),
      })
    } else {
      setDateSelection({ year: undefined, month: undefined, day: undefined })
    }
  }

  function handleDayBlur(event: FocusEvent<HTMLInputElement>) {
    onBlur(event)
  }

  function handleYearBlur(event: FocusEvent<HTMLInputElement>) {
    onBlur(event)
  }

  function handleDayFocus(event: FocusEvent<HTMLInputElement>) {
    onFocus(event)
  }

  function handleYearFocus(event: FocusEvent<HTMLInputElement>) {
    onFocus(event)
  }

  function handleNativeDateBlur(event: FocusEvent<HTMLInputElement>) {
    onBlur(event)
  }

  function handleNativeDateFocus(event: FocusEvent<HTMLInputElement>) {
    onFocus(event)
  }

  const monthOptions = useMemo(() => {
    return Array.from(Array(12).keys()).map(month => ({
      value: month.toString(),
      label: intl.formatDate(Date.UTC(2000, month, 1, 0, 0, 0), { month: 'long' }),
    }))
  }, [intl])

  const inputClass = classNames(
    'c-input',
    { [`c-input--${String(styling)}`]: styling !== undefined },
    { [`c-input--${String(theme)}`]: theme !== undefined },
  )

  const shouldRenderNative = useMemo(
    () => isNativeDatePickerSupported() && (isIOS(userAgent) || isAndroid(userAgent)),
    [userAgent],
  )

  if (shouldRenderNative) {
    return (
      <DeprecatedInputText
        name="birthdate"
        type="date"
        title={title}
        styling={styling}
        disabled={disabled}
        value={value}
        validation={validation}
        onChange={handleNativeDateChange}
        onBlur={handleNativeDateBlur}
        onFocus={handleNativeDateFocus}
      />
    )
  }

  return (
    <label htmlFor={name} className={inputClass}>
      {title && <div className="c-input__title">{title}</div>}
      <div className="c-input__content input-birthdate__content">
        <DeprecatedInputText
          placeholder={translate('day')}
          name="day"
          type={disableHtmlValidations ? undefined : 'number'}
          styling="tight"
          disabled={disabled}
          value={dateSelection.day}
          onChange={handleDateFieldChange('day')}
          onBlur={handleDayBlur}
          onFocus={handleDayFocus}
          testId="day-input"
          autoComplete={autoComplete}
        />
        <InputSelect
          name="month"
          styling={InputSelect.Styling.Tight}
          theme={theme}
          required={!disableHtmlValidations}
          disabled={disabled}
          placeholder={translate('month')}
          value={dateSelection.month ?? ''}
          onChange={handleDateFieldChange('month')}
          options={monthOptions}
        />
        <DeprecatedInputText
          name="year"
          type={disableHtmlValidations ? undefined : 'number'}
          styling="tight"
          disabled={disabled}
          placeholder={translate('year')}
          value={dateSelection.year}
          onChange={handleDateFieldChange('year')}
          onBlur={handleYearBlur}
          onFocus={handleYearFocus}
          testId="year-input"
          autoComplete={autoComplete}
        />
      </div>
      {validation && <div className="c-input__note">{validation}</div>}
    </label>
  )
}

export default InputBirthdate
