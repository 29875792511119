'use client'

import { Dialog } from '@vinted/web-ui'

import { useTranslate } from '@marketplace-web/shared/i18n'

type Props = {
  url: string | undefined
  onCancel: () => void
  onContinue: () => void
}

const LeavingVintedModal = ({ url, onCancel, onContinue }: Props) => {
  const translate = useTranslate('leaving_vinted_modal')

  return (
    <Dialog
      show={!!url}
      testId="leaving-vinted-modal"
      title={translate('title')}
      body={translate('body')}
      actions={[
        {
          callback: onContinue,
          style: Dialog.ActionStyling.Filled,
          text: translate('actions.continue'),
          testId: 'leaving-vinted-modal--continue-button',
          url,
          urlProps: { target: '_blank', rel: 'noreferrer' },
        },
        {
          callback: onCancel,
          style: Dialog.ActionStyling.Flat,
          text: translate('actions.cancel'),
          testId: 'leaving-vinted-modal--cancel-button',
        },
      ]}
    />
  )
}

export default LeavingVintedModal
