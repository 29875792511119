'use client'

import { ReactNode } from 'react'
import { Button, Cell, Dialog, InfoBanner, Navigation, Spacer, Text } from '@vinted/web-ui'
import { X24 } from '@vinted/monochrome-icons'

import { AccessChannel } from 'constants/index'
import { FaqEntryType } from 'constants/faq-entry'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { clickEvent } from '_libs/common/event-tracker/events'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import FaqEntryUrl from 'components/FaqEntryUrl'

type Props = {
  url: string | undefined
  onCancel: () => void
  onContinue: () => void
}

const LeavingVintedModalVariantA = ({ url, onCancel, onContinue }: Props) => {
  const translate = useTranslate('conversation.conversation_info.leaving_vinted_dialog')
  const { track } = useTracking()

  const handleLearnMoreClick = () => {
    track(
      clickEvent({
        screen: Screen.MessageReply,
        target: ClickableElement.LearnMoreLeavingModal,
        targetDetails: url,
      }),
    )
  }

  const handleStayOnVintedClick = () => {
    track(
      clickEvent({
        screen: Screen.MessageReply,
        target: ClickableElement.StayOnVintedLeavingModal,
        targetDetails: url,
      }),
    )

    onCancel()
  }

  const learnMoreTranslationValues = {
    'link-learn-more': (chunks: Array<ReactNode>) => (
      <FaqEntryUrl
        type={FaqEntryType.SpamPhishing}
        accessChannel={AccessChannel.ProductLink}
        render={faqUrl => (
          <a href={faqUrl} target="_blank" rel="noreferrer" onClick={handleLearnMoreClick}>
            {chunks}
          </a>
        )}
      />
    ),
  }

  return (
    <Dialog show={!!url} testId="leaving-vinted-modal">
      <Navigation
        right={
          <Button
            iconName={X24}
            styling={Button.Styling.Flat}
            onClick={onCancel}
            testId="leaving-vinted-modal-close-button"
            inline
          />
        }
      />
      <Cell styling={Cell.Styling.Wide}>
        <Text
          as="h1"
          type={Text.Type.Heading}
          alignment={Text.Alignment.Center}
          text={translate('title')}
        />
        <Spacer size={Spacer.Size.Large} />
        <Text as="span" text={translate('description', learnMoreTranslationValues)} />
        <Spacer size={Spacer.Size.Large} />
        <InfoBanner
          type={InfoBanner.Type.Warning}
          body={
            <a href={url} onClick={onContinue} target="_blank" rel="noopener noreferrer">
              {translate('continue')}
            </a>
          }
          styling={InfoBanner.Styling.Tight}
        />
        <Spacer size={Spacer.Size.X2Large} />
        <Button
          onClick={handleStayOnVintedClick}
          styling={Button.Styling.Filled}
          text={translate('stay_on_vinted')}
        />
      </Cell>
    </Dialog>
  )
}

export default LeavingVintedModalVariantA
