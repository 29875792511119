'use client'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import { clickEvent } from '_libs/common/event-tracker/events'
import { AbTestVariant } from 'constants/abtest'
import { useAbTest } from '@marketplace-web/shared/ab-tests'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'

import LeavingVintedModal from './LeavingVintedModal'
import LeavingVintedModalVariantA from './LeavingVintedModalVariantA'
import LeavingVintedModalVariantB from './LeavingVintedModalVariantB'

type Props = {
  url: string | undefined
  onClose(): void
}
const LeavingModalExperiment = ({ url, onClose }: Props) => {
  const leavingModalAbTest = useAbTest('external_link_warning')?.variant
  const { track } = useTracking()

  const handleCancel = () => {
    track(
      clickEvent({
        screen: Screen.MessageReply,
        target: ClickableElement.CancelLeavingModal,
        targetDetails: url,
      }),
    )

    onClose()
  }

  const handleOnContinue = () => {
    if (!url) return

    track(
      clickEvent({
        screen: Screen.MessageReply,
        target: ClickableElement.ProceedFromLeavingModal,
        targetDetails: url,
      }),
    )

    onClose()
  }

  if (leavingModalAbTest === AbTestVariant.A) {
    return (
      <LeavingVintedModalVariantA url={url} onCancel={handleCancel} onContinue={handleOnContinue} />
    )
  }

  if (leavingModalAbTest === AbTestVariant.B) {
    return (
      <LeavingVintedModalVariantB url={url} onCancel={handleCancel} onContinue={handleOnContinue} />
    )
  }

  return <LeavingVintedModal url={url} onCancel={handleCancel} onContinue={handleOnContinue} />
}

export default LeavingModalExperiment
